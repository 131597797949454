import { SVGProps, memo } from "react";

function Brain(props: SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
            <g clipPath="url(#prefix__clip0_2138_49603)">
                <path
                    d="M5.75 0C6.716 0 7.5.783 7.5 1.75v12.5a1.751 1.751 0 01-3.49.184 2 2 0 01-2.397-2.597A2.5 2.5 0 011.43 7.24C1.16 6.9 1 6.44 1 6c0-.96.675-1.76 1.575-1.956A2.003 2.003 0 014.01 1.56 1.75 1.75 0 015.75 0zm6.24 1.56a2.002 2.002 0 011.435 2.484C14.325 4.24 15 5.04 15 6c0 .44-.16.9-.431 1.24a2.498 2.498 0 01-.182 4.598 2.002 2.002 0 01-2.397 2.597 1.751 1.751 0 01-3.49-.185V1.75a1.75 1.75 0 013.49-.19z"
                    fill={props.color || "#505A68"}
                />
            </g>
            <defs>
                <clipPath id="prefix__clip0_2138_49603">
                    <path fill="#fff" d="M0 0h16v16H0z" />
                </clipPath>
            </defs>
        </svg>
    );
}

const MemoBrain = memo(Brain);
export default MemoBrain;
