import { News } from "@/types/api";
import NewsDashboardDetail from "@app/dashboard/NewsDashboardDetail";
import NewsItemContext from "@app/dashboard/news-feed/news-list/NewsItemContext";

function FeaturedNews({ news }: { news: News }) {
    return (
        <div className="relative flex h-[calc((100%-8px)/2)] flex-col overflow-hidden rounded-lg border-1 border-[#252932] bg-[#1C1F27]">
            <NewsItemContext.Provider value={news}>
                <NewsDashboardDetail />
            </NewsItemContext.Provider>
        </div>
    );
}
export default FeaturedNews;
