import Vote from "@/components/vote/Vote";
import SentimentPopup from "@app/dashboard/SentimentPopup";
import NewItemTag from "@app/dashboard/news-feed/news-list/NewsItemTag";
import MemoBrain from "@assets/SVG/Brain";
import MemoFrowningEmoji from "@assets/SVG/Sentiment/FrowningEmoji";
import MemoNeutralEmoji from "@assets/SVG/Sentiment/NeutralEmoji";
import MemoSmilingEmoji from "@assets/SVG/Sentiment/SmilingEmoji";
import { isEmpty, maxBy, uniqBy } from "lodash";
import { useState } from "react";
import { PositiveBadge } from "@/components/badge/statistics";
import { News } from "@/types/api";

export function Headline({ news }) {
    return (
        <h1 className="border-b-1 border-[#272C34] px-2 py-3 text-lg font-normal leading-6 text-white">
            {news?.headline}
        </h1>
    );
}

export function Tags({
    tags,
    addSearchItem,
}: {
    tags: {
        type: string;
        data: string;
    }[];
    addSearchItem: (tag: string, category: string) => void;
}) {
    return (
        <div className="relative">
            <div className="scrollbar-hide flex max-w-full grow items-center gap-3 overflow-hidden overflow-x-auto scroll-smooth whitespace-nowrap">
                {tags?.map((tag, i) => (
                    <NewItemTag
                        tag={tag}
                        isRead={false}
                        isSelected={false}
                        key={i}
                        onClick={() => addSearchItem(tag.data, "tags")}
                    />
                ))}
            </div>
        </div>
    );
}

export function Actions({ sentimentData, iconType }) {
    let sentimentIcon;
    let sentimentColor;

    const [popupVisible, setPopupVisible] = useState(false);
    const [posX, setPosX] = useState(0);
    const [posY, setPosY] = useState(0);

    if (!isEmpty(sentimentData)) {
        const highestSentiment = maxBy(Object.keys(sentimentData), (sentiment) => sentimentData[sentiment]);

        if (highestSentiment === "positive") {
            sentimentIcon = "Positive";
            sentimentColor = "#27AF8F";
        } else if (highestSentiment === "negative") {
            sentimentIcon = "Negative";
            sentimentColor = "#C83D4D";
        } else {
            sentimentIcon = "Neutral";
            sentimentColor = "#7A869B";
        }
    }

    return (
        <div className="flex items-center justify-end gap-3 p-0">
            <div className="w-[fit-content]">
                <Vote decorated={true} />
            </div>
            {iconType !== "Press Release" && <div className="grow"></div>}
            {iconType !== "Government" && iconType !== "Press Release" && iconType !== "Twitter" && (
                <div className="flex items-center justify-center gap-2 p-0">
                    <MemoBrain className="h-4 w-4" />
                    <p className="text-xs font-light text-[#7A869B]">A.I. Article Summary</p>
                </div>
            )}
            {!isEmpty(sentimentData) && iconType !== "Press Release" && (
                <div className="h-4 w-[1px] bg-[#2F343F]" />
            )}
            {iconType !== "Press Release" && (
                <div
                    className="flex cursor-pointer items-center gap-[2px] p-0 hover:brightness-75"
                    onPointerEnter={(e: any) => {
                        e.stopPropagation();
                        setPopupVisible(true);
                        setPosX(e.pageX);
                        setPosY(e.pageY);
                    }}
                    onPointerLeave={(e: any) => {
                        e.stopPropagation();
                        setPopupVisible(false);
                    }}
                >
                    <div className="flex h-4 w-4 items-center justify-center">
                        <div className="m-0 flex w-4">
                            {popupVisible && !isEmpty(sentimentData) && (
                                <SentimentPopup sentimentData={sentimentData} X={posX} Y={posY} />
                            )}
                            {sentimentIcon === "Positive" && <MemoSmilingEmoji />}
                            {sentimentIcon === "Neutral" && <MemoNeutralEmoji />}
                            {sentimentIcon === "Negative" && <MemoFrowningEmoji />}
                        </div>
                    </div>
                    <p className={`text-xs font-light text-[${sentimentColor}]`}>{sentimentIcon}</p>
                </div>
            )}
        </div>
    );
}

export const getUpdatedURL = (pathname, params, key, value) => {
    // Clone the current query object to avoid mutating the original
    const updatedQuery = { ...params, [key]: value, chartType: "mentioned" };

    // Construct the new URL using the current pathname and the updated query object
    const newURL = {
        pathname: pathname,
        query: updatedQuery,
    };

    return newURL;
};

export function ChangeSincePublished({
    news,
    assets,
    updatedURL,
    addSearchItem,
}: {
    news: News;
    assets: any[];
    updatedURL: {
        pathname: any;
        query: any;
    };
    addSearchItem: (tag: string, category: string) => void;
}) {
    const uniqAssets = uniqBy(assets, "slug");

    if (uniqAssets?.length < 1 || news.iconType === "Press Release") return <div />;

    return (
        <div
            className={`flex w-full items-center justify-between border-[#272C34] ${
                news.iconType === "Twitter" ? "border-b-1 px-2 pb-2" : "border-y-1 p-2"
            }`}
        >
            <div className="relative flex w-[calc(100%-80px)] items-center gap-2">
                <p className="w-[65px] shrink-0 text-[10px] font-light leading-3 text-[#7A869B]">
                    Change since published
                </p>
                <div className="relative flex w-full gap-1">
                    <div className="horizontal-scroll-gradient absolute -bottom-[1px] -left-[8px] h-[26px] w-3 rotate-180" />
                    <div className="scrollbar-hide flex w-full items-center gap-1 overflow-x-scroll scroll-smooth px-[0.4rem]">
                        {uniqAssets?.map((asset: any) => (
                            <div key={asset?.slug} onClick={() => addSearchItem(asset.symbol, "assets")}>
                                <PositiveBadge asset={asset} from={news.publishedAt} />
                            </div>
                        ))}
                    </div>
                    <div className="horizontal-scroll-gradient absolute -bottom-[1px] -right-[6px] h-[26px] w-3" />
                </div>
            </div>
            {/* <Link href={updatedURL} className="cursor-pointer text-xs font-normal text-[#2E72FF]">
                See on Chart
            </Link> */}
        </div>
    );
}

export function NewsContent({ news }) {
    const processedContent = (news?.content || news?.headline)?.replace(/\n/g, "<br>");
    const processedEnrichment = news?.enrichment?.content?.replace(/\n/g, "<br>");

    return (
        <div className="scrollbar-hide flex grow flex-col overflow-y-scroll px-2 py-1">
            <div className="news-detail-gradient absolute bottom-0 left-0 h-40 w-full" />
            <p
                className="mb-2 list-disc space-y-2 pt-[6px] text-justify text-sm font-light text-[#A1ADC1]"
                dangerouslySetInnerHTML={{ __html: processedContent }}
            />
            <p
                className="mb-12 list-disc pb-[4.5rem] pt-[6px] text-justify text-sm font-light text-[#A1ADC1]"
                dangerouslySetInnerHTML={{ __html: processedEnrichment }}
            />
        </div>
    );
}
