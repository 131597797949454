import MemoSmilingEmoji from "@assets/SVG/Sentiment/SmilingEmoji";
import { useContext, useEffect, useState } from "react";
import MemoCopy from "@assets/SVG/Copy";
import Vote from "@/components/vote/Vote";
import { ShareModal } from "@app/dashboard/[articleId]/news-detail/ShareModal";
import SourceIcon from "@/components/source/SourceIcon";
import MemoBrain from "@assets/SVG/Brain";
import { CurrencyDollarIcon, ShareIcon } from "@heroicons/react/24/outline";
import format from "date-fns/format";
import MemoNeutralEmoji from "@assets/SVG/Sentiment/NeutralEmoji";
import MemoFrowningEmoji from "@assets/SVG/Sentiment/FrowningEmoji";
import { useCopyToClipboard, useWindowSize } from "react-use";
import Alert from "@/components/Alert";
import MemoFlame from "@assets/SVG/Flame";
import NewsItemContext from "@app/dashboard/news-feed/news-list/NewsItemContext";
import Link from "next/link";
import { compact, isEmpty, maxBy, uniq, uniqBy } from "lodash";
import { Tags } from "@app/dashboard/[articleId]/news-detail/NewsDetailUtils";
import isYesterday from "date-fns/isYesterday";
import isToday from "date-fns/isToday";
import { SymbolLabel } from "@app/TSWidgets/components/SymbolLabel";
import {
    extractStringsFromArray,
    getArticleSource,
    transformStringsToArrayOfObjects,
    UpgradeSection,
} from "@/types/util";
import { BookmarkIcon } from "@heroicons/react/24/outline";
import { BookmarkIcon as BookMarkSolid } from "@heroicons/react/24/solid";
import { createNewBookmarkEntry, deleteBookmark } from "@/services/bookmarks";
import cookie from "js-cookie";
import bookmarkedListState from "@/store/bookmarkStore";
import { globalOverlayProxy } from "@/store/globalStore";
import SentimentPopup from "@app/dashboard/SentimentPopup";
import { outOfContextProxy } from "@/store/searchStore";
import userPaymentState from "@/store/userPaymentStore";
import { useSnapshot } from "valtio";
import { UpgradeModal } from "@app/dashboard/news-feed/news-list/UpgradeModal";
import { PaymentFeedbackModal } from "@app/profile/subscription/PaymentFeedbackModal";

export default function NewsDashboardDetail() {
    const news = useContext(NewsItemContext);
    const [show, setShow] = useState(false);
    const [numberOfLines, setNumberOfLines] = useState(3);
    const [bookmarked, setBookmarked] = useState(false);
    const [popupVisible, setPopupVisible] = useState(false);
    const { height } = useWindowSize();
    const publishedAt = new Date(news?.publishedAt);
    const newsSource = getArticleSource(news.source);
    const subscriptionSnapshot = useSnapshot(userPaymentState);
    const userIsPro = subscriptionSnapshot.subIsActive;
    const [showPaymentFeedbackModal, setShowPaymentFeedbackModal] = useState(false);
    const [showUpgradeModal, setShowUpgradeModal] = useState(false);

    useEffect(() => {
        setBookmarked(news.bookmarked as boolean);
    }, [news.bookmarked]);

    // const showSentimentModal = (open) => {};

    let sentiment;
    let sentimentColor;
    const sentimentData = news?.data?.sentiment;

    if (!isEmpty(sentimentData)) {
        const highestSentiment = maxBy(Object.keys(sentimentData), (sentiment) => sentimentData[sentiment]);

        if (highestSentiment === "positive") {
            sentiment = "Positive";
            sentimentColor = "#27AF8F";
        } else if (highestSentiment === "negative") {
            sentiment = "Negative";
            sentimentColor = "#C83D4D";
        } else {
            sentiment = "Neutral";
            sentimentColor = "#7A869B";
        }
    }

    const addSearchItem = (tag: string, category: string) => {
        outOfContextProxy.tag = tag;
        outOfContextProxy.category = category;
    };

    const handleAddBookmark = () => {
        setAlertMessage("Article Saved");
        setShowAlert(true);
        createNewBookmarkEntry({
            jwt: cookie.get("stytch_session_jwt") as string,
            slug: news.slug,
            url: news.url as string,
        }).then(() => {
            const allBookmarks = bookmarkedListState.data;
            bookmarkedListState.data = [...allBookmarks, news.slug];
        });
    };

    const handleDeleteBookmark = () => {
        setAlertMessage("Article Unsaved");
        setShowAlert(true);
        deleteBookmark({
            jwt: cookie.get("stytch_session_jwt") as string,
            slug: news.slug,
        }).then(() => {
            const index = bookmarkedListState.data.findIndex((item) => item === news.slug);
            const allBookmarks = bookmarkedListState.data;
            allBookmarks.splice(index, 1);
            bookmarkedListState.data = allBookmarks;
        });
    };

    useEffect(() => {
        const textContainer = document.querySelector(".text-div");

        const lineHeight = 20; //1.25rem

        if (textContainer) {
            const containerHeight = parseFloat(window.getComputedStyle(textContainer).height);

            const numLines = Math.floor((containerHeight - 8) / lineHeight); //the 8 is the padding on top

            setNumberOfLines(numLines);
        }
    }, [height]);

    const [value, copy] = useCopyToClipboard();
    const [showAlert, setShowAlert] = useState(false);
    const [posX, setPosX] = useState(0);
    const [posY, setPosY] = useState(0);
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const dateIsYesterday = isYesterday(publishedAt);
    const dateIsToday = isToday(publishedAt);

    let renderedDate;

    if (dateIsToday) {
        renderedDate = `${format(publishedAt, "paa")}`;
    } else if (dateIsYesterday) {
        renderedDate = `Yesterday, ${format(publishedAt, "paa")}`;
    } else {
        if (Number(format(publishedAt, "Y")) === currentYear) {
            renderedDate = `${format(publishedAt, "paa")}, ${format(publishedAt, "MMM do")}`;
        } else {
            renderedDate = `${format(publishedAt, "paa")}, ${format(publishedAt, "MMM do Y")}`;
        }
    }

    const extractedStrings = uniq(extractStringsFromArray(news?.tagsData || news?.tags));

    const transformedTags: {
        type: string;
        data: string;
    }[] = compact(transformStringsToArrayOfObjects(extractedStrings));

    // a case where the tag is a full object on its own
    const tagsWithType = news.tags.filter((tag) => tag.type);
    const renderedTags = uniqBy([...transformedTags, ...tagsWithType], (tag) => tag.data.toLowerCase());

    const [alertMessage, setAlertMessage] = useState("");

    let newsContent = `${news?.content || news?.headline}\n\n${news?.enrichment?.connect}`;

    if (!news?.enrichment?.content) newsContent = news?.content || news?.headline;

    if (!news) return null;

    return (
        <>
            {showPaymentFeedbackModal && (
                <PaymentFeedbackModal show={showPaymentFeedbackModal} setShow={setShowPaymentFeedbackModal} />
            )}

            {show && (
                <ShareModal
                    news={news}
                    show={show}
                    setShow={setShow}
                    value={value}
                    copy={copy}
                    setShowAlert={setShowAlert}
                    setAlertMessage={setAlertMessage}
                />
            )}

            <UpgradeModal
                show={showUpgradeModal}
                setShow={setShowUpgradeModal}
                setShowPaymentFeedbackModal={setShowPaymentFeedbackModal}
                message={UpgradeSection.Bookmark}
            >
                <></>
            </UpgradeModal>
            {/* the div below is a trick used to render the line-clamp properties at build time so they can be captured in the css */}
            <div className="hidden">
                <div className="line-clamp-[1]" />
                <div className="line-clamp-[2]" />
                <div className="line-clamp-[3]" />
                <div className="line-clamp-[4]" />
                <div className="line-clamp-[5]" />
                <div className="line-clamp-[6]" />
                <div className="line-clamp-[7]" />
                <div className="line-clamp-[8]" />
                <div className="line-clamp-[9]" />
                <div className="line-clamp-[10]" />
                <div className="line-clamp-[11]" />
                <div className="line-clamp-[12]" />
                <div className="line-clamp-[13]" />
                <div className="line-clamp-[14]" />
                <div className="line-clamp-[15]" />
                <div className="line-clamp-[16]" />
                <div className="line-clamp-[17]" />
                <div className="line-clamp-[18]" />
                <div className="line-clamp-[19]" />
                <div className="line-clamp-[20]" />
            </div>
            {/* the div above is a trick used to render the line-clamp properties at build time so they can be captured in the css */}
            <Alert show={showAlert} setShow={setShowAlert} content={alertMessage ?? "Headline Copied"} />
            <div className="flex items-center justify-between border-b-1 border-[#252932] px-2 py-[6px]">
                <div
                    className="flex cursor-pointer items-center gap-2 hover:brightness-75"
                    onClick={() => addSearchItem(newsSource, "sources")}
                >
                    <SourceIcon
                        className={`h-6 w-6 ${news?.iconType === "Twitter" && "rounded-full bg-black"}`}
                        iconType={news?.iconType}
                    />
                    <div className="flex flex-col">
                        <p className="flex gap-1 text-sm font-normal leading-4 text-white">
                            <span>{newsSource}</span> {news?.ai?.hot && <MemoFlame />}
                        </p>
                        <p className="text-left text-xs font-light text-[#7A869B]">{renderedDate}</p>
                    </div>
                </div>
                <div className="flex items-center">
                    <div className="flex items-center gap-[17.5px] text-[#7A869B]">
                        <div className="relative self-center">
                            {news.assets?.length > 0 && news.iconType !== "Press Release" && (
                                <div
                                    className="z-0 m-0 flex cursor-pointer items-center gap-1 hover:brightness-75"
                                    onClick={() => addSearchItem(news?.assets?.[0]?.symbol, "assets")}
                                >
                                    <CurrencyDollarIcon className="h-4 w-4" color={"#D9CC5B"} />

                                    <div
                                        className={`cursor-pointer whitespace-nowrap text-[10px] font-light uppercase leading-3 ${"text-[#D9CC5B]"}`}
                                    >
                                        <SymbolLabel Symbol={news?.assets?.[0]?.symbol} />
                                        {news?.assets?.length > 1 && <> +{news?.assets?.length}</>}
                                    </div>
                                </div>
                            )}
                        </div>
                        {renderedTags?.length > 0 && news.iconType !== "Press Release" && (
                            <Tags tags={renderedTags} addSearchItem={addSearchItem} />
                        )}
                        <div className="h-0 w-4 rotate-90 border-1 border-solid border-[#373C46]" />
                        <div
                            onClick={() => {
                                copy(news.headline + " - from BlockBeat.io");
                                setAlertMessage("Headline copied!");
                                setShowAlert(false);
                                setShowAlert(true);
                            }}
                        >
                            <MemoCopy className="h-[14px] w-[14px] cursor-pointer hover:brightness-75" />
                        </div>
                        {bookmarked ? (
                            <BookMarkSolid
                                onClick={() => {
                                    if (!userIsPro) {
                                        globalOverlayProxy.setZIndex = true;
                                        return setShowUpgradeModal(true);
                                    }
                                    setBookmarked(false);
                                    handleDeleteBookmark();
                                }}
                                strokeWidth={2.5}
                                className="h-[14px] w-[14px] cursor-pointer hover:brightness-75"
                            />
                        ) : (
                            <BookmarkIcon
                                onClick={() => {
                                    if (!userIsPro) {
                                        globalOverlayProxy.setZIndex = true;
                                        return setShowUpgradeModal(true);
                                    }
                                    setBookmarked(true);
                                    handleAddBookmark();
                                }}
                                strokeWidth={2.5}
                                className="h-[14px] w-[14px] cursor-pointer hover:brightness-75"
                            />
                        )}
                        <ShareIcon
                            onClick={() => {
                                globalOverlayProxy.setZIndex = true;
                                setShow(true);
                            }}
                            className="h-[14px] w-[14px] cursor-pointer hover:brightness-75"
                            strokeWidth={2.5}
                        />
                    </div>
                </div>
            </div>
            <div className="h-[calc(100%-109px)] grow">
                <div className="flex flex-col border-b-1 border-[#252932] px-2 py-3">
                    <h1 className="line-clamp-2 pb-0 text-lg font-normal leading-6 text-white">
                        {news?.headline}
                    </h1>
                </div>

                <div className="text-div z-0 h-[calc(100%-72px)] grow px-2 pt-2">
                    <p
                        className={`line-clamp-[${
                            numberOfLines > 0 ? numberOfLines : 1
                        }] text-justify text-sm font-light text-[#A1ADC1]`}
                        dangerouslySetInnerHTML={{ __html: newsContent }}
                    />
                </div>
            </div>

            <div className="z-10 flex flex-col rounded-b-lg bg-[#1C1F27] px-2">
                <div className="flex w-full flex-col gap-3">
                    <div className="flex items-center justify-between gap-2 p-0">
                        <div className="w-[fit-content]">
                            <Vote />
                        </div>
                        <div className="relative flex items-center gap-3 p-0">
                            <div className="flex items-center justify-center gap-2 p-0">
                                <MemoBrain className="h-4 w-4" />
                                <p className="text-xs font-light text-[#7A869B]">A.I. Article Summary</p>
                            </div>

                            {!!sentiment && <div className="h-4 w-[1px] bg-[#2F343F]" />}
                            {!!sentiment && (
                                <div
                                    onPointerEnter={(e: any) => {
                                        e.stopPropagation();
                                        setPopupVisible(true);
                                        setPosX(e.pageX);
                                        setPosY(e.pageY);
                                    }}
                                    onPointerLeave={(e: any) => {
                                        e.stopPropagation();
                                        setPopupVisible(false);
                                    }}
                                    className="relative flex cursor-pointer items-center gap-[2px] p-0 hover:brightness-75"
                                >
                                    {popupVisible && !isEmpty(sentimentData) && (
                                        <SentimentPopup sentimentData={sentimentData} X={posX} Y={posY} />
                                    )}

                                    <div className="flex h-4 w-4 items-center justify-center">
                                        <div className="m-0 flex w-4">
                                            {sentiment === "Positive" && <MemoSmilingEmoji />}
                                            {sentiment === "Neutral" && <MemoNeutralEmoji />}
                                            {sentiment === "Negative" && <MemoFrowningEmoji />}
                                        </div>
                                    </div>
                                    <p className={`text-xs font-light text-[${sentimentColor}]`}>
                                        {sentiment}
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <Link href={`/dashboard/${news.slug}?chart=${"btc"}`} legacyBehavior>
                    <div className="flex cursor-pointer flex-col items-center justify-center hover:brightness-75">
                        <h1 className="pb-0 text-sm font-normal leading-6 text-[#2E72FF]">Read More</h1>
                    </div>
                </Link>
            </div>
        </>
    );
}
