"use client";

import MemoFullBlockbeatLogo from "@assets/SVG/Logo/FullBlockbeatLogo";
import Tooltip from "@/components/Tooltip";
import bookmarkedListState from "@/store/bookmarkStore";
import { News } from "@/types/api";
import FeaturedNews from "@app/dashboard/FeaturedNews";
// import { SymbolLabel } from "@app/TSWidgets/components";
// import MainChart from "@app/TSWidgets/components/MainChart/MainChart";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
// import ChartContainer2 from "@app/dashboard/[articleId]/news-detail/ChartContainer2";
import { uniq } from "lodash";
import { useSnapshot } from "valtio";
import EODMarketSummary from "./EODMarketSummary";
import { vercelEnv } from "@/services/environment";

export function Dashboard({ viralNews }: { viralNews: News }) {
    const allBookmarks = useSnapshot(bookmarkedListState);

    return (
        <div className="fixed flex h-[calc(100vh-68px)] w-[inherit] flex-col gap-2 overflow-y-hidden">
            <FeaturedNews
                news={{
                    ...viralNews,
                    read: false,
                    publishedAt: viralNews?.publishedAt
                        ? viralNews.publishedAt
                        : new Date(viralNews?.NK as number).toISOString(), //2023-07-19T10:55:53.813Z
                    bookmarked: uniq(allBookmarks.data).includes(viralNews.SK as string),
                    slug: viralNews?.slug ? viralNews.slug : (viralNews.SK as string),
                }}
            />
            <div className="h-[calc((100%-8px)/2)] w-full overflow-hidden rounded-lg border-1 border-[#252932] bg-[#1C1F27]">
                {/* <div
                className={`${
                    height > 1300
                        ? "h-[calc((100%-16px)/4)]"
                        : height > 1120
                        ? "h-[calc((100%-16px)/3)]"
                        : "h-[calc((100%-8px)/2)]"
                } w-full overflow-hidden rounded-lg border-1 border-[#252932] bg-[#1C1F27]`}
            > */}
                {vercelEnv === "production" ? (
                    <div className="relative h-full">
                        <span className="absolute left-1/2 top-1/2 z-50 flex -translate-x-1/2 -translate-y-1/2 flex-col items-center gap-6 text-sm text-primary">
                            <MemoFullBlockbeatLogo className="scale-[12] opacity-50" />
                            <Tooltip
                                className="tooltip-custom-warning flex cursor-pointer items-center justify-center gap-1 text-xs font-light hover:brightness-95"
                                content="Coming Soon"
                            >
                                <p className="whitespace-nowrap text-[#7A869B] opacity-50">Charts Locked</p>
                                <QuestionMarkCircleIcon className="h-4 w-4 text-[#7A869B] opacity-50" />
                            </Tooltip>
                        </span>
                    </div>
                ) : (
                    // <div className="h-full overflow-hidden">
                    //     <ChartContainer2 />
                    // </div>
                    <EODMarketSummary />
                )}
            </div>
        </div>
    );
}
